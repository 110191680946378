/* #eva-root, #eva-root .App {
  height: 100%;
} */

.minimizable-web-chat > button.maximize {
  background-color: transparent;
  border-radius: 30px;
  border: none;
  bottom: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  color: White;
  font-size: 30px;
  height: auto;
  outline: 0;
  padding: 12px 15px 12px 50px;
  position: fixed;
  right: 20px;
  width: auto;
  background-color: #0075c9;
  background-image: url('./images/new_eva_icons/EVAwhite_lg.png');
  background-position: 5px center;
  background-repeat: no-repeat;
  background-size: 45px;
  transition: all 300ms ease-in-out;
  animation-name: slideInUp;
  animation-duration: .5s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9999 !important;
  border: 2px solid #c0c0c0;
}
.minimizable-web-chat > button.maximize.it_maximize_btn {
  padding: 8px 40px 8px 13px;
  border-radius: 15px;
  background-image: url('./images/new_eva_icons/chat_bubble.png');
  background-position: calc(100% - 10px) center;
  background-size: 20px;
  border: none;
}
button.beta_tag::after {
    content: 'BETA';
    position: absolute;
    display: block;
    top: -10px;
    right: -15px;
    background: #39af1c;
    padding: 2px 5px;
    border-radius: 25px;
    font-weight: 600;
    font-size: 0.5em;
}
.minimizable-web-chat.left_align > button.maximize {
  left: 20px;
  right: inherit;
}
.minimizable-web-chat > button.maximize > span {
  /* display: none !important; */
  font-size: 20px;
  font-weight: 600;
}

.minimizable-web-chat > button.maximize > .red-dot {
  color: #EF0000;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 5px;
}

.minimizable-web-chat > button.maximize:focus:not(:active),
.minimizable-web-chat > button.maximize:hover:not(:active) {
  background-color: #069;
  /* border-color: #069; */
}

/* .minimizable-web-chat > button.maximize:active {
  background-color: White;
  color: #39C;
} */
/* ---- minimize and maximize Animation ---- */
@keyframes fb_bounce_out_v2 {
	0% {
		opacity: 1;
		transform: scale(1, 1);
		transform-origin: bottom right
	}
	100% {
		opacity: 0;
		transform: scale(0, 0);
		transform-origin: bottom right
	}
}
@keyframes fb_bounce_in_v2 {
	0% {
		opacity: 0;
		transform: scale(0, 0);
		transform-origin: bottom right
	}
	50% {
		transform: scale(1.03, 1.03);
		transform-origin: bottom right
	}
	100% {
		opacity: 1;
		transform: scale(1, 1);
		transform-origin: bottom right
	}
}
@keyframes slideInUp {
  0% {
    transform: translate3d(0,100%,0);
    visibility: visible;
  }
}
.minimizable-web-chat > .chat-box {
  background-color: rgba(255, 255, 255, .8);
  backdrop-filter: blur(10px);
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  border: 1px solid #005b96;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  min-width: 320px;
  max-height: 650px;
  height: calc(100% - 20px);
  position: fixed;
  bottom: 20px;
  width: 100%;
  /* animation-duration: 800ms;
  animation-name: fb_bounce_in_v2; */
  transition: all 300ms ease-in-out;
  animation-name: slideInUp;
  animation-duration: 1.2s;
  transition-timing-function: ease-in;
  z-index: 99999 !important;
}

.minimizable-web-chat > .chat-box.hide {
  display: none;
  max-height: 0;
  min-height: 0;
  bottom: -20px !important;
  transition: all 300ms ease-in-out;
  animation-duration: 800ms;
  /* animation-name: fb_bounce_out_v2; */
  transition-timing-function: ease-in;
}

.minimizable-web-chat > .chat-box.left {
  left: 20px;
}

.minimizable-web-chat > .chat-box.right {
  right: 20px;
}

.minimizable-web-chat > .chat-box > header {
  background-color: #007ac9;
  height: 40px;
  display: flex;
  margin-bottom: 0;
}

.minimizable-web-chat > .chat-box > header > .filler {
  flex: 0 10000 100%;
}

.minimizable-web-chat > .chat-box > header > button {
  width: 40px;
  height: 40px;
  background-color: Transparent;
  border: 0;
  color: White;
  outline: 0;
}

.minimizable-web-chat > .chat-box > header > button:focus:not(:active),
.minimizable-web-chat > .chat-box > header > button:hover:not(:active) {
  color: rgba(255, 255, 255, .6);
}

.minimizable-web-chat > .chat-box > header > button:focus:active {
  color: rgba(255, 255, 255, .4);
}

.minimizable-web-chat > .chat-box > .react-web-chat {
  flex: 1;
}

/* responsive css */
@media (max-width: 767px) {
  .minimizable-web-chat > .chat-box {
    height: 100%;
  }

  .minimizable-web-chat > .chat-box.left {
    left: 0;
    bottom: 0;
  }
  
  .minimizable-web-chat > .chat-box.right {
    right: 0;
    bottom: 0;
  }
}

.cds-inst .switch, .cds-inst .minimize, .cds-inst .maximize {
  display: none !important;
}

/* cds-inst styling */
.cds-inst .webchat_allignmnet {
  padding: 10px;
}
.cds-inst, .cds-inst > .App {
  display: flex;
  width: 100%;
  height: 100%;
}
.cds-inst .minimizable-web-chat > .chat-box {
  max-height: 800px;
  border-radius: 10px;
  position: inherit;
  height: 100%;
  overflow: hidden;
}

.cds-inst .minimizable-web-chat > .chat-box > header {
  border-radius: 10px 10px 0 0;
}
.cds-inst .chat-box div[role="complementary"] {
  background: #fafafa;
}
.cds-inst .sso__upperRight {
  z-index: 999999;
}