.web-chat {
  overflow: hidden;
}

.connect-spinner {
  display: flex;
  margin: auto;
  text-align: center;
}

.connect-spinner > .content {
  margin: auto;
}

.connect-spinner > .content > .icon {
  font-size: 64px;
}

div[role="form"] .webchat__send-box__main > div:nth-child(2) {
  display: none;
}
.attachment_icon {
  width: 20px !important
}
.attachment_icon.visible {
  display: flex !important;
}
.attachment_icon button {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  cursor: pointer;
}
.attachment_icon button svg {
  width: 100% !important;
}
div[aria-controls="webchatSendBoxMicrophoneButton"] > button {
  display: block !important;
}

.Eva-alignleft {
  text-align: left;
  padding: 15px 15px 15px 15px;
}

.Eva-danger-color {
  color:#FF0000;
}

/* notification Box */
#notification {
  position: absolute;
  bottom: 8px;
  left: 40px;
  z-index: 9;
  box-shadow: 0px 0px 9px 0px rgb(176 178 179 / 60%) !important;
  -moz-box-shadow: 0px 0px 9px 0px rgba(176, 178, 179, 0.6) !important;
  -webkit-box-shadow: 0px 0px 9px 0px rgb(176 178 179 / 60%) !important;
  border-radius: 3px;
  padding: 12px 10px 10px;
  max-width: 180px;
  width: 100%;
  background-color: rgba(0, 122, 201, 0.9);
  color: #fff;
}
#notification::before {
  color: rgba(0, 122, 201, 0.9);
  border-right: 8px solid;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  display: inline-block;
  content: '';
  position: absolute;
  left: -8px;
  bottom: 8px;
}
#notification .notification_close_btn{
  position: absolute;
  top: 7px;
  right: 10px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px !important;
  font-family: sans-serif;
  font-weight: lighter;
}
#notification .notification_hd{
  font-size: 14px;
  margin: 0 0 8px 0;
  font-weight: 600;
  padding-right: 10px;
}
#notification #newFeaturePoints ul {
  margin: 5px 0 0 0;
  padding-left: 15px;
  list-style-type: disc;
  list-style-position: outside;
}
#notification #newFeaturePoints ul li {
  margin-bottom: 5px;
}
#notification button{
  margin: 10px 0 0 0;
  cursor: pointer;
  border-radius: 3px;
  width: 100%;
  border: none;
  background-color: #fff;
  color: #007ac9;
  padding: 4px;
  font-weight: 600;
}
.liveChat_conversation .webchat__stacked-layout__main .webchat__defaultAvatar > div:first-child {
  background-image: url('images/ecolab-icon-transparent.png') !important;
  background-color: transparent !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  text-indent: -999999 px !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
}