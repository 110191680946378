.webchat_allignmnet *:not(i), .webchat_allignmnet *:not(i)::before, .webchat_allignmnet *:not(i)::after , .webchat_allignmnet:not(i){
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
}
.webchat_allignmnet{
  z-index: 99999;
}
.webchat_allignmnet *{
  outline: none;
}
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
.react-web-chat {
  width: 100%;
}
.webchat_allignmnet button[title="Upload file"] > div {
    padding: 0;
}
.webchat_allignmnet{
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px; */
}
section.sp-scroll main .webchat_allignmnet{
  min-height: calc(100vh - 70px);
  max-height: calc(100vh - 70px);
  padding-top: 0;
  padding-bottom: 5px;
}
.ac-media .ac-media-playButton{
  border: 2px solid #000;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
nav.navbar[role="navigation"]{
  margin-bottom: 0 !important;
}
.react-web-chat{
  flex: 1 0 0;
}
.react-web-chat > div[role="complementary"]{
  display: flex;
  flex-direction: column;
  background: #fafafa;
  position: relative;
  border-radius: 0 0 4px 4px;
}
.react-web-chat > div[role="complementary"] .webchat__toaster{
  display: none;
}
.css-k9up9f > div.slider > div.button, .css-1lkmrry > div.slider > div, .css-1rbksw3 > div.slider > div{
  background-color: rgba(160, 161, 163, 0.8);
}
.css-k9up9f:hover > div.slider > div.button, .css-1lkmrry:hover > div.slider > div, .css-1rbksw3:hover > div.slider > div, .css-k9up9f:focus > div.slider > div.button, .css-1lkmrry:focus > div.slider > div, .css-1rbksw3:focus > div.slider > div{
  background-color: rgba(160, 161, 163, 1);
}
.react-web-chat div[role="complementary"] div[role="log"] > div{
  display: flex;
  /* flex-direction: column-reverse; */
}
.webchat__basic-transcript__scrollable > .webchat__basic-transcript__filler{
  display: none;
}
button.css-1m1alj7 {
  display: none;
}
#header{
  background-color: #007AC9;
  border-color: #005b96;
  -moz-transition: background-color 0.4s ease-out, border-color 0.4s ease-out;
  -o-transition: background-color 0.4s ease-out, border-color 0.4s ease-out;
  -webkit-transition: background-color 0.4s ease-out, border-color 0.4s ease-out;
  transition: background-color 0.4s ease-out, border-color 0.4s ease-out;
  position: relative;
  height: 40px;
  min-height: 40px;
  border-bottom: 1px solid;
  color: #ffffff;
  width: 100%;
  padding: 0 10px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
div[role="form"] .webchat__send-box__main{
  margin: 0 auto;
  width: 98%;
  margin-bottom: 4px;
  margin-top: 5px;
  border: thin solid #007ac2 !important;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: white;
}
div[role="form"] .webchat__send-box__main > form{
  padding-top: 0;
  padding-bottom: 0; 
  padding-left: 5px;
  padding-right: 5px;
}
div[role="form"] .webchat__send-box__main > div.css-1lzfo2b{
  width: 25px;
  display: none;
}
/* div[role="form"] .webchat__send-box__main > div:last-child {
  display: none;
} */
div[role="form"] .webchat__send-box__main form input{
  height: 36px;
}
.header_ac i{
  font-weight: 300;
}
.header_ac .minimize , .header_ac .close {
  margin: 0 5px;
  font-weight: 400;
  cursor: pointer;
  color: #ffffff;
  opacity: 1;
}
.header_ac .close i{
  font-size: 2em;
}
.chat_hd{
  display: flex;
  width: 100%;
  padding: 3px 0;
  height: 100%;
}
.chat_hd>span{
  display: inline-flex;
}
.chat_hd .chat_ic{
  background-image: url('images/new_eva_icons/EVAwhite_lg.png');
  background-position: center;
  background-size: cover;
  width: 30px;
  margin-right: 10px;
  margin-left: 10px;
}
.chat_hd .chat_ic.hd_chat_ic_it {
  background-repeat: no-repeat;
	background-image: url('./images/new_eva_icons/chat_bubble.png');
	background-size: auto;
}
.chat_hd .hd_title{
  width: calc(100% - 20px);
  font-size: 1.15em;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-weight: 700;
}
.close_eva{
  color: #ffffff;
}
ul[role="list"] li[role="listitem"] .webchat__stacked-layout--from-user .webchat__stacked-layout__avatar-gutter > div > div:first-child{
  background-image: url('images/person_ic.png');
  background-position: center;
  background-size: cover;
  text-indent: -999999px;
}
ul[role="list"] li >div:not(.webchat__stacked-layout--from-user) .webchat__stacked-layout__avatar-gutter > div > div:first-child, .webchat__carousel-filmstrip__avatar-gutter > div > div:first-child{
  background-image: url('images/new_eva_icons/EVAwhite_lg.png');
  background-color: rgba(255, 255, 255, 0.6);
  background-color: #007AC9;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  text-indent: -999999px;
}
/* -- space decrease --*/
.content .attachment > div > .ac-container{
  padding: 10px !important;
}
button.ac-pushButton{
  padding: 5px 10px !important;
}
.ac-actionSet{
  flex-direction: row !important;
  flex-wrap: wrap;
}
.ac-actionSet .ac-pushButton{
  flex: none !important;
  margin-right: 5px;
  margin-bottom: 2px;
  margin-top: 2px;
  display: inline-block !important;
  border-radius: 15px;
  font-weight: 400 !important;
  color: #007ac9 !important;
  cursor: pointer;
  border-color: #007ac9;
  max-width: 100%;
  border-width: 0.5px !important;
}
.ac-actionSet .ac-pushButton.style-positive, .ac-actionSet .ac-pushButton.style-positive[aria-pressed="true"], .ac-actionSet .ac-pushButton:hover, .ac-actionSet .ac-pushButton[aria-pressed="true"]:hover{
  background-color: #007ac9 !important;
  color: #ffffff !important;
  border-color: #007ac9 !important;
}
.ac-actionSet .ac-pushButton:last-child{
  margin-right: 0;
}
.ac-actionSet .ac-pushButton[aria-pressed="true"] {
  background: inherit !important;
  border-color: inherit !important;
}
ul[role="list"] li[role="listitem"] .ac-adaptiveCard[tabindex="0"]{
  padding: 15px !important;
}
.webchat__stacked-layout__attachment .webchat__bubble__content .ac-container{
  padding: 0 !important;
}
.from-user.bubble , .webchat__stacked-layout__content .message .bubble{
  min-height: inherit;
}
.webchat_allignmnet .webchat__stacked-layout--from-user p.plain{
  padding: 8px 12px;
}
.ac-input.ac-multichoiceInput{
  padding: 5px !important;
}
.webchat_allignmnet .content > ul{
  border: none;
  border-radius: 5px;
  padding: 0 !important;
  margin: 5px;
}
.webchat_allignmnet .webchat__stacked-layout__attachment , .webchat_allignmnet .webchat__stacked-layout__content .webchat__stacked-layout__message .webchat__bubble__content{
  background-color: #ffffff !important;
  border: none !important;
  border-radius: 0px 20px 20px 20px !important;
  box-shadow: 0px 0px 9px 0px rgba(176, 178, 179, 0.6) !important;
  -moz-box-shadow: 0px 0px 9px 0px rgba(176, 178, 179, 0.6) !important;
  -webkit-box-shadow: 0px 0px 9px 0px rgba(176, 178, 179, 0.6) !important;
  margin: 5px !important;
}
.content > ul{
  padding: 10px !important;
}
.content > ul > li > div, .webchat__carousel-filmstrip__content .webchat__carousel-filmstrip__attachments > li > div {
  background: #ffffff;
  /* border: 1px solid rgba(0, 122, 201, 0.6); */
  box-shadow: 0px 0px 8px 0px rgba(176, 178, 179, 0.6);
  -moz-box-shadow: 0px 0px 8px 0px rgba(176, 178, 179, 0.6);
  -webkit-box-shadow: 0px 0px 8px 0px rgba(176, 178, 179, 0.6);
  border-radius: 20px;
}
.webchat__carousel-filmstrip__content .webchat__carousel-filmstrip__attachments {
  margin: 5px !important;
}
.webchat__carousel-filmstrip__content .webchat__carousel-filmstrip__attachments > li {
  max-width: 320px !important;
}
.webchat__carousel-filmstrip__content .webchat__carousel-filmstrip__attachments > li:first-child {
  padding-left: 0 !important;
}
.content > ul > li:first-child > div{
  border-radius: 0px 20px 20px 20px;
}
/* .ac-horizontal-separator{
  height: auto !important;
} */
.content >.message  + ul , .content >.message + .attachment{
  margin-top: 0 !important;
}
.webchat__stacked-layout--from-user .webchat__stacked-layout__content .webchat__stacked-layout__message .webchat__bubble__content{
  background-color: #ffffff !important;
  border: none !important;
  border-radius: 20px 20px 0 20px !important;
}
.webchat__stacked-layout__avatar-gutter, .webchat__carousel-filmstrip__avatar-gutter{
  margin-right: 10px;
}
.webchat__stacked-layout--from-user .webchat__stacked-layout__avatar-gutter{
  margin-right: 0;
  margin-left: 10px;
}
.webchat__stacked-layout__attachment{
  padding: 10px !important;
}
.webchat__stacked-layout__attachment .webchat__bubble__content, .webchat__bubble__content{
  background: none !important;
  border: none !important;
  min-height: inherit !important;
}
.webchat__stacked-layout__attachment .css-1vieo9r .ac-container[tabindex="0"]{
  padding: 0 !important;
}

/* order summary custom css */

div.ac-container div.ac-columnSet div.ac-selectable{
  cursor: pointer !important;
}
div.ac-container div.ac-columnSet div.ac-selectable[aria-label="Tracking Data"]{
  padding: 0 !important;
}
div.ac-container div.ac-columnSet div.ac-selectable[aria-label="Tracking Data"] .ac-textBlock p{
  text-decoration: underline !important;
  color: #007AC9 !important;
}
div.ac-container[aria-label="order details card"] div.ac-container .ac-factset .ac-fact-title .ac-textBlock p{
  white-space: nowrap !important;
} 
div.ac-container[aria-label="order details card"] div.ac-container div.ac-columnSet div.ac-container:not(.ac-selectable) > div > img.ac-image{
  width: 25px !important;
  height: 25px !important;
}

/* bottom suggestion buttons css */

div[role="form"] {
  background: #dff0fa;
  border-radius: 0 0 4px 4px;
}
.webchat__suggested-actions__carousel {
  background: #dff0fa;
  padding: 10px 5px 5px 5px !important;
}
.webchat__suggested-actions__carousel ul li{
  padding-left: 5px;
  padding-right: 5px;
}
ul li > div.webchat__suggested-action{
  padding: 0 !important;
}
ul li .webchat__suggested-action__button{
  display: inline-block !important;
  border-radius: 15px !important;
  font-weight: 400;
  color: #007ac9 !important;
  cursor: pointer;
  border-color: #007ac9 !important;
  border-width: 0.5px !important;
  line-height: normal;
  height: auto !important;
  padding: 6px 10px !important;
}
ul li .webchat__suggested-action__button:hover{
  background-color: #007ac9 !important;
  color: #ffffff !important;
}
.webchat__suggested-actions__carousel div.css-gtdio3:hover button[aria-label="left"] .slider{
  left: -8px;
}
.webchat__suggested-actions__carousel div.css-gtdio3:hover button[aria-label="right"] .slider{
  right: -8px;
}
button[aria-label="Left"] {
  left: -50px !important;
}
.webchat__carousel-layout .react-film__main:hover button[aria-label="Left"] {
  left: 0 !important;
}

.webchat__stacked-layout__attachment .css-1vieo9r .ac-container[aria-label="order tracking card"], .webchat__stacked-layout__attachment .css-1vieo9r .ac-container[aria-label="order summary card"]{
  padding: 10px !important;
  background: #ffffff !important;
  border: 1px solid rgba(0, 122, 201, 0.6) !important;
  border-radius: 5px !important;
}

.webchat__stacked-layout__content .webchat__bubble__content .ac-container .ac-textBlock > p {
  text-overflow: inherit;
  white-space: normal;
  overflow: auto;
  /* color: #000000; */
  line-height: normal;
}

.ac-columnSet .ac-container {
  padding: 2px !important;
}
.webchat__bubble__nub-pad, .webchat__carousel-filmstrip__nub-pad{
  width: auto !important;
}

/* onbase detergent */
.ac-container[aria-label="Laundry Detergent Plus"] .ac-image{
  width: auto !important;
  max-width: 100% !important;
}

@media (max-width: 375px) {
  .webchat__stacked-layout__content>.webchat__row.attachment>.bubble {
    min-width: inherit !important;
  }
}

/* browser hack for mozilla firefox */
@-moz-document url-prefix() {
  .from-user.avatar {
    -moz-force-broken-image-icon: 0;
  }
}

/* space reduce for icons and removed unused spaces*/
@media (max-width: 420px) {
  .webchat__carouselFilmStrip__avatar + .content {
    margin-left: 5px !important;
  }
  .webchat__carousel_indented_content{
    padding-left: 0 !important;
  }
  .react-web-chat ul[role="list"] li[role="listitem"] >div {
    margin: 0 5px 0 0;
  }
  .css-10xzw44:not(.webchat__stacked-layout--rtl):not(.webchat__stacked-layout--from-user).webchat__stacked_indented_content > .webchat__stacked-layout__avatar-gutter, [data-css-10xzw44]:not(.webchat__stacked-layout--rtl):not(.webchat__stacked-layout--from-user).webchat__stacked_indented_content > .webchat__stacked-layout__avatar-gutter{
    margin-right: 5px;
  }
  .css-10xzw44:not(.webchat__stacked-layout--rtl).webchat__stacked-layout--from-user.webchat__stacked_indented_content > .webchat__stacked-layout__avatar-gutter, [data-css-10xzw44]:not(.webchat__stacked-layout--rtl).webchat__stacked-layout--from-user.webchat__stacked_indented_content > .webchat__stacked-layout__avatar-gutter {
    margin-left: 5px;
  }
  .webchat__stacked-layout__avatar-gutter > div > div, .webchat__stacked-layout__avatar-gutter > div, .webchat__carouselFilmStrip__avatar > div > div, .webchat__carouselFilmStrip__avatar > div, .webchat__stacked-layout__avatar-gutter{
    width: 26px !important;
    height: 26px !important;
  }
  .react-web-chat ul[role="list"] li[role="listitem"] >div:not(.webchat__stacked-layout--from-user) .webchat__stacked-layout__avatar-gutter > div > div.webchat__imageAvatar, .react-web-chat ul[role="list"] li[role="listitem"] >div .webchat__carouselFilmStrip__avatar > div > div.webchat__imageAvatar{
    display: none;
  }
  .react-web-chat ul[role="list"] li[role="listitem"] .webchat__stacked-layout--from-user .webchat__stacked-layout__avatar-gutter > div > div.webchat__imageAvatar{
    display: block;
  }
  .react-web-chat ul[role="list"] li[role="listitem"] >div:not(.webchat__stacked-layout--from-user) .webchat__stacked-layout__avatar-gutter > div > div:first-child, .react-web-chat ul[role="list"] li[role="listitem"] >div .webchat__carouselFilmStrip__avatar > div > div:first-child{
    margin-left: 0;
    margin-right: 0;
    width: 26px;
    height: 26px;
    background-size: cover;
  }
  .react-web-chat ul[role="list"] li[role="listitem"] .webchat__stacked-layout--from-user .webchat__stacked-layout__avatar-gutter > div > div.webchat__imageAvatar{
    margin-right: 0;
    margin-left: 0;
    width: 26px;
    height: 26px;
    background-size: cover;
  }
  .css-1uq1cg4 > .content > .message , .css-1uq1cg4 > .content > ul , .css-1uq1cg4 > .content > .webchat__row{
    margin-left: 0;
  }
  .css-1uq1cg4 > .content > ul li{
    max-width: 250px;
  }
}

/* hamburer Menu */
ul#hamburger_options {
  position: absolute;
  bottom: 45px;
  margin: 0;
  list-style-type: none;
  padding: 0;
  border: 1px solid #007ac9;
  background: #ffffff;
  border-radius: 5px;
  left: 6px;
  z-index: 999;
}
#hamburger_options li {
  padding: 5px 8px;
  color: #007ac9;
  font-weight: 600;
  cursor: pointer;
  font-size: 12px;
}
#hamburger_options li:hover {
  background: #007ac9;
  color: #ffffff;
}
div[role="form"] .webchat__send-box__main #openhamburgermenu {
  background-image: url('images/hamburger_menu.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 4px;
  display: flex !important;
  height: 38px;
  width: 30px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
div[role="form"] .webchat__send-box__main #openhamburgermenu span {
  font-weight: 700;
  color: #007ac9;
  font-size: 18px;
  line-height: normal;
  word-spacing: -2px;
  margin-top: -7px;
}
.webchat__typingIndicator {
  background-size: 80%;
  background-position: bottom left;
  margin-bottom: -10px;
  margin-left: -10px;
}

div[role="form"] .webchat__send-box__main #openhamburgermenu.none{
  display: none !important;
}

/* Thumbs up/down styling */
.botActivityDecorator {
  position: relative;
}
.botActivityDecorator .botActivityDecorator_content {
  padding-left: 0px;
}
.botActivityDecorator .botActivityDecorator_buttonBar {
  list-style-type: none;
  margin: 0;
  padding: 4px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  /* box-shadow: 0px 0px 5px 0px rgba(176, 178, 179, 0.6);
  -moz-box-shadow: 0px 0px 5px 0px rgba(176, 178, 179, 0.6);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(176, 178, 179, 0.6); */
  border-radius: 5px;
  top: 40px;
  left: 10px;
  width: 40px;
  z-index: 2;
  /* display: none; */
}
.botActivityDecorator:hover .botActivityDecorator_buttonBar {
  display: flex;
}
.botActivityDecorator .botActivityDecorator_buttonBar li, .botActivityDecorator .botActivityDecorator_buttonBar li button {
  border-radius: 5px;
  cursor: pointer;
}
.botActivityDecorator .botActivityDecorator_buttonBar .botActivityDecorator_button {
  background: transparent;
  border: solid 0px #e6e6e6;
  margin-bottom: 0px;
  padding: 0;
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: 20px;
  background-repeat: no-repeat;
}
.botActivityDecorator .botActivityDecorator_buttonBar .thumbs_up_button .botActivityDecorator_button {
  background-image: url('./images/thumbs_icons/ThumbsUp-BlueOutline.png');
}
.botActivityDecorator .botActivityDecorator_buttonBar .thumbs_down_button .botActivityDecorator_button {
  background-image: url('./images/thumbs_icons/ThumbsDown-GrayOutline.png');
}
.botActivityDecorator .botActivityDecorator_buttonBar .thumbs_up_button:hover .botActivityDecorator_button, .botActivityDecorator .botActivityDecorator_buttonBar .thumbs_up_button .botActivityDecorator_button:hover, .botActivityDecorator .botActivityDecorator_buttonBar .thumbs_up_button .botActivityDecorator_button.active {
  background-image: url('./images/thumbs_icons/ThumbsUp-Blue.png');
}
.botActivityDecorator .botActivityDecorator_buttonBar .thumbs_down_button:hover .botActivityDecorator_button, .botActivityDecorator .botActivityDecorator_buttonBar .thumbs_down_button .botActivityDecorator_button:hover, .botActivityDecorator .botActivityDecorator_buttonBar .thumbs_down_button .botActivityDecorator_button.active {
  background-image: url('./images/thumbs_icons/ThumbsDown-Gray.png');
}
@media (max-width: 420px) {
  .botActivityDecorator .botActivityDecorator_buttonBar{
    width: 26px;
    left: 0;
  }
  .botActivityDecorator .botActivityDecorator_buttonBar .botActivityDecorator_button{
    width: 26px;
    height: 26px;
    background-size: 18px;
    top: 30px
  }
}
.minimizable-web-chat div[class*="focus"] {
  display: none !important;
}

/* cds-inst styling */
.cds-inst div[role="form"] {
  border-radius: 0 0 10px 10px;
}